import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ApiService } from './api-service';
import { ErrorService } from './error.service';

const CACHE_SIZE = 1;

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService {
  private userCache: Observable<User>;

  constructor(private http: HttpClient, private error: ErrorService) {
    super();
  }

  get currentUser() {
    if (!this.userCache) {
      this.userCache = this.requestCurrentUser().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.userCache;
  }

  requestCurrentUser() {
    return this.http.get<any>(`${this.apiUrl}/auth/info`).pipe(
      catchError(this.error.authError<any>())
    );
  }

  forceReload() {
    this.userCache = null;
  }
}
