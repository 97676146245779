import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.userService.currentUser.pipe(
      map(currentUser => {
        for (let i = 0; i < currentUser.roles.length; i++) {
          if ((route.data.roles as Array<string>).some(role => role === currentUser.roles[i])) {
            return true;
          }
        }
      this.router.navigateByUrl('/forbidden', { skipLocationChange: true });
      return false;
    }));
  }
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
