import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api-service';
import { ErrorService } from './error.service';


@Injectable({ providedIn: 'root' })
export class AuthService extends ApiService {
  public isRefreshing = false;

  constructor(private http: HttpClient, public error: ErrorService) {
    super();
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${this.apiUrl}/auth/getToken`, { username: username , password: password })
      .pipe(map(result => {
        if (result && result.token && result.refreshToken) {
          localStorage.setItem('access_token', result.token);
          localStorage.setItem('refresh_token', result.refreshToken);
        }
        return result;
      }));
  }

  refreshToken() {
    this.isRefreshing = true;
    const refreshToken = localStorage.getItem('refresh_token');
    return this.http.post<any>(`${this.apiUrl}/auth/refreshToken`, { refreshToken })
      .pipe(map(result => {
        if (result && result.token && result.refreshToken) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.setItem('access_token', result.token);
          localStorage.setItem('refresh_token', result.refreshToken);
        }
        this.isRefreshing = false;
        return result;
      }));
  }

  isAccessTokenExpired() {
    const accessToken = localStorage.getItem('access_token');
    const jwtHelper = new JwtHelperService();
    return accessToken && jwtHelper.isTokenExpired(accessToken);
  }

  checkConfirmToken(token: string): Observable<any> {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return this.http.get<any>(`${this.apiUrl}/auth/confirm?token=${token}`).pipe(
      catchError(this.error.unexpectedError<any>())
    );
  }

  confirmUser(token: string, data: any): Observable<any> {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return this.http.post<any>(`${this.apiUrl}/auth/confirm?token=${token}`, data).pipe(
      catchError(this.error.unexpectedError<any>())
    );
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/passwordReset`, {email: email}).pipe(
      catchError(this.error.unexpectedError<any>())
    );
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
}
