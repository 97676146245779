/**
 * Translation designator for "extract-translations" (i18n)
 */
export function _t<T extends string | string[]>(s: string): string {
  return s;
}

export function getLangIdByCode(code: string): number | null {
  if ('en' === code) {
    return 0;
  }

  if ('pl' === code) {
    return 1;
  }

  return null;
}
