import { Component, EventEmitter, Inject , Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface DialogData {
  title: string;
  message: string;
  submitButtonName: string;
  submitButtonIcon: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})

export class ConfirmationModalComponent {
  public modalTitle: string;
  public modalMessage: string;
  public submitButtonName: string;
  public submitButtonIcon: string;

  @Output()
  submitChange: EventEmitter<boolean>;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.modalMessage = data.message;
    this.modalTitle = data.title;
    this.submitButtonName = data.submitButtonName;
    this.submitButtonIcon = data.submitButtonIcon;

    this.submitChange = new EventEmitter<boolean>();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.submitChange.emit(true);
    this.dialogRef.close();
  }

}
