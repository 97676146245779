import { Component } from '@angular/core';
import { NgProgress } from '@ngx-progressbar/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent  {
  title = 'CoSTE';

  constructor(
    public progress: NgProgress,
    translate: TranslateService
  ) {
    // set default language
    translate.addLangs(['pl', 'en']);
    translate.setDefaultLang('pl');
  }
}
