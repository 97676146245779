import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

export interface DialogData {
  title: string;
  message: string;
  detailsInfo: string;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent implements OnInit {
  public modalTitle: string;
  public message: string;
  public detailsInfo: string;
  public isProduction: boolean = environment.production;

  @Output()
  closeChange: EventEmitter<boolean>;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.closeChange = new EventEmitter<boolean>();
    this.modalTitle = data.title;
    this.message = data.message;
    this.detailsInfo = data.detailsInfo;
  }

  ngOnInit() {
  }

  closeDialog(): void {
    this.closeChange.emit(true);
    this.dialogRef.close();
  }

}
