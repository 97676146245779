import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { _t } from 'src/app/misc/translations-helper';

@Injectable()
export class TranslateMatPaginatorIntl extends MatPaginatorIntl {

  private rangeLabelIntl: string;

  constructor(private translate: TranslateService) {
    super();
    this.getTranslations();
  }

  getTranslations() {
    this.itemsPerPageLabel = this.translate.instant(_t('PAGINATOR.ITEMS_PER_PAGE'));
    this.nextPageLabel = this.translate.instant(_t('PAGINATOR.NEXT_PAGE'));
    this.previousPageLabel = this.translate.instant(_t('PAGINATOR.PREVIOUS_PAGE'));
    this.rangeLabelIntl = this.translate.instant(_t('PAGINATOR.RANGE'));
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.rangeLabelIntl} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = ((page * pageSize) > length) ?
      (Math.ceil(length / pageSize) - 1) * pageSize :
      page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return `${(startIndex + 1)}-${endIndex} ${this.rangeLabelIntl} ${length}`;
  }

}
