import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { _t } from 'src/app/misc/translations-helper';
import { ErrorDialogComponent } from '../components/common/error-dialog/error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  public showUnexpectedError: boolean = true;
  public error: any;

  constructor(
    public dialog: MatDialog,
    private location: Location,
    private router: Router
  ) { }

  public deleteHandleError<T> (result?: T) {
    return this.deleteHandleErrorWithMessage(
      error => _t('Errors.Delete.Message'),
      result
    );
  }

  public deleteHandleErrorWithMessage<T>(messageProvider: (any) => string, result?: T) {
    return (error: any): Observable<T> => {
      if (error.status == 500 || error.status == 400) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: _t('Errors.Delete.Title'),
            message: messageProvider(error),
          },
          panelClass: 'confirmation',
        });
      }

      return throwError(error);
    };
  }

  public unexpectedError<T> (result?: T) {
    return (error: any): Observable<T> => {
      this.error = error;

      if (error.status == 500) {
        this.showUnexpectedErrorDialog(error);
      }

      return throwError(error);
    };
  }

  public authError<T> (result?: T) {
    return (error: any): Observable<T> => {
      this.error = error;
      if (error.status == 401) {
        const redirectUrl = this.location.path() === '/login' ? null : this.location.path();
        this.router.navigate(['/login'], { queryParams: { redirectUrl }});
      } else if ([0, 500].includes(error.status)) {
        this.showUnexpectedErrorDialog(error);
        this.router.navigateByUrl('login');
      }
      return throwError(error);
    };
  }

  private showUnexpectedErrorDialog(error) {
    if (!this.showUnexpectedError) {
      return;
    }
    this.showUnexpectedError = false;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        title: _t('Errors.Service.Title'),
        message: _t('Errors.Service.Message'),
        detailsInfo: error.message,
      },
      panelClass: 'confirmation',
    });
    dialogRef.componentInstance.closeChange.subscribe((showDialog) => {
      this.showUnexpectedError = showDialog;
    });
  }

}
