import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { _t, getLangIdByCode } from 'src/app/misc/translations-helper';
import { ErrorDialogComponent } from '../components/common/error-dialog/error-dialog.component';
import { ApiService } from './api-service';

@Injectable({providedIn: 'root'})
export class FileUploadServiceService extends ApiService {
  private translate: TranslateService;

  constructor(private http: HttpClient, public dialog: MatDialog, translate: TranslateService) {
    super();
    this.translate = translate;
  }

  public upload(files: Array<File>,
                serviceName: string,
                errorCallback: (error: any) => void): { [key: string]: Observable<number> } {
    const result = {};

    files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('file', file);

      const req = new HttpRequest('POST', `${this.apiUrl}/${serviceName}`, formData, {
        reportProgress: true,
      });
      const progress = new Subject<number>();
      const fileResult = new Subject<any>();

      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
          fileResult.next(event.body);
        }
      }, error => {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: _t('Errors.FileUpload.Title'),
            message: _t('Errors.FileUpload.FileUploadError'),
          },
        });
        if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors['form.file']) {
          console.log(error.error.errors['form.file'][getLangIdByCode(this.translate.getDefaultLang())]);
        }
        console.error('FileUpload-Error', error);
        errorCallback(error);
      });

      result[file.name] = {
        progress: progress.asObservable(),
        fileResult: fileResult,
      };
    });

    return result;
  }
}
