import { ContractType } from './contract-type.model';
import { TaxOffice } from './tax-office.model';

export enum CitizenshipSelection {
  POLISH = 'POLISH',
  UKRAINIAN = 'UKRAINIAN',
}

export class NaturalPersonDetails {
  id?: number;
  contractType: ContractType;
  taxOffice: TaxOffice | number;
  citizenshipSelection: CitizenshipSelection;
  professionCode?: string;
}
