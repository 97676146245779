import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { NgProgressModule } from '@ngx-progressbar/core';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from '../environments/environment';
import { MasterRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationModalComponent } from './components/common/confirmation-modal/confirmation-modal.component';
import { ErrorDialogComponent } from './components/common/error-dialog/error-dialog.component';
import { ErrorPageComponent } from './components/common/error-page/error-page.component';
import { FileUploadModalComponent } from './components/common/file-upload-modal/file-upload-modal.component';
import { MaterialModule } from './material.module';
import { tokenGetter } from './misc/auth';
import { ErrorInterceptor } from './misc/error-interceptor';
import { httpLoaderFactory } from './misc/i18n';

import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localePL from '@angular/common/locales/pl';
import 'moment/locale/pl';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { TranslateMatPaginatorIntl } from 'src/app/misc/TranslateMatPaginatorIntl';
import { DateRangeModalComponent } from './components/common/date-range-modal/date-range-modal.component';
import { JwtRefreshInterceptor } from './misc/jwt-refresh-interceptor';
import { AppConfigService } from 'src/app/services/app-config.service';

registerLocaleData(localePL, 'pl');
registerLocaleData(localeEN, 'en');

export const initAppConfig = (appConfigService: AppConfigService) => {
  return () => {
    return appConfigService.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
    ErrorDialogComponent,
    ErrorPageComponent,
    FileUploadModalComponent,
  ],
  entryComponents: [
    ConfirmationModalComponent,
    ErrorDialogComponent,
    FileUploadModalComponent,
  ],
  imports: [
    BlockUIModule.forRoot(),
    BlockUIHttpModule.forRoot({
      blockAllRequestsInProgress: true,
      }),
    HttpClientModule,
    RouterModule.forRoot(MasterRoutes),
    NgProgressModule.withConfig({}),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.whitelistedDomains,
        blacklistedRoutes: [],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    LayoutModule,
    FlexLayoutModule,
  ],
  exports: [
    FlexLayoutModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initAppConfig, deps: [ AppConfigService ], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtRefreshInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: TranslateMatPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
