import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
  title: string;
  subtitle: string;

  constructor(private location: Location,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      const prefix = data.errorCode === 404
        ? 'ErrorPage.404'
        : 'ErrorPage.403';
      this.title = prefix + '.Title';
      this.subtitle = prefix + '.Subtitle';
    });
  }

  back(): void {
    this.location.back();
  }

}
