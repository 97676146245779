import { environment } from 'src/environments/environment';

let apiUrlCache: string;
let publicApiUrlCache: string;

export function apiUrl(isTest = false): string {
  if (isTest === true) {
    return '/api';
  } else {
    if (apiUrlCache === undefined) {
      apiUrlCache = ('apiUrl' in environment)
        ? (environment as any).apiUrl
        : '/api';
    }
  }
  return apiUrlCache;
}

export function publicApiUrl(isTest = false): string {
  if (isTest === true) {
    return '/publicApi';
  } else {
    if (publicApiUrlCache === undefined) {
      publicApiUrlCache = ('publicApiUrl' in environment)
        ? (environment as any).publicApiUrl
        : '/publicApi';
    }
  }
  return publicApiUrlCache;
}
