import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from '../components/common/error-dialog/error-dialog.component';
import { _t } from './translations-helper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public showErrorDialog: boolean = true;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private masterProgressBar: NgProgress
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      switch (err.status) {
        case 403: {
          this.router.navigateByUrl('/forbidden', { skipLocationChange: true });
          break;
        }
        case 404: {
          this.router.navigateByUrl('/not-found', { skipLocationChange: true });
          break;
        }
        case 401: {
          if (err.error.message === 'Expired JWT Token') {
            this.masterProgressBar.ref().complete();
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              data: {
                title: _t('Errors.Auth.TokenExpired.Title'),
                message: _t('Errors.Auth.TokenExpired.Message'),
              },
              panelClass: 'confirmation',
            });
            dialogRef.beforeClosed().subscribe(() => {
              this.dialog.closeAll();
              this.router.navigateByUrl('/login');
            });
          }

          break;
        }
        case 0:
          break;
      }

      return throwError(err);
    }));
  }
}
