import { apiUrl, publicApiUrl } from '../misc/api';

export abstract class ApiService {
  apiUrlCache: string;
  private _isTest = false;

  protected isTest(v: boolean) {
    this._isTest = v;
  }

  protected get apiUrl(): string {
    return apiUrl(this._isTest);
  }

  protected get publicApiUrl(): string {
    return publicApiUrl(this._isTest);
  }

}
