import { Routes } from '@angular/router';
import { ErrorPageComponent } from './components/common/error-page/error-page.component';

export const MasterRoutes: Routes = [{
  path: '',
  children: [{
    path: '',
    loadChildren: './components/public/public.module#PublicModule',
  }],
}, {
  path: 'main',
  children: [{
    path: '',
    loadChildren: './components/core/core.module#CoreModule',
  }],
}, {
  path: 'not-found',
  component: ErrorPageComponent,
  data: { errorCode: 404 },
}, {
  path: 'forbidden',
  component: ErrorPageComponent,
  data: { errorCode: 403 },
}, {
  path: '**',
  component: ErrorPageComponent,
  data: { errorCode: 404 },
}];
