// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {CitizenshipSelection} from 'src/app/models/natural-person-details.model';

export const environment = {
  production: true,
  // apiUrl: 'https://medicon.coste.pl/api',
  // publicApiUrl: '//medicon.coste.pl/publicApi',
  apiUrl: 'https://coste.digitup.pl/api',
  publicApiUrl: '//coste.digitup.pl/publicApi',
  // apiUrl: 'http://myapp.loc/api',
  // publicApiUrl: '//myapp.loc/publicApi',
  whitelistedDomains: ['myapp.loc', 'coste.digitup.pl', 'digitup.com.pl', 'medicon.coste.pl',
    '51.83.185.3/plesk-site-preview/backend.coste.digitup.pl/https/51.83.185.3', '51.83.185.3'],
  maxFileSizeMB: 256,
  defaultCitizenship: CitizenshipSelection.POLISH,
  staticFiles: [
    /** {
      name: 'Dokument zgody na udział w CoSTE',
      url: 'http://www.africau.edu/images/default/sample.pdf',
    },
     {
      name: 'Formularz danych osobowych - osoba fizyczna + druk do US',
      url: 'http://www.africau.edu/images/default/sample.pdf',
    },
     {
      name: 'Formularz danych osobowych - działalność gospodarcza + druk do US',
      url: 'http://www.africau.edu/images/default/sample.pdf',
    },
     {
      name: 'Umowa Prezentacja HCP + Bezpieczeństwo Monitorowania Farmakoterapii',
      url: 'http://www.africau.edu/images/default/sample.pdf',
    },
     {
      name: 'Umowa Biblioteka slajdów + Bezpieczeństwo Monitorowania Farmakoterapii',
      url: 'http://www.africau.edu/images/default/sample.pdf',
    },**/
    {
      name: 'Formularz zgody w sprawie danych osobowych',
      url: 'https://medicon.coste.pl/files/FZ.pdf',
    },
    {
      name: 'FORMULARZ PV',
      url: 'https://medicon.coste.pl/files/PV.pdf',
    },
    {
      name: 'Wycofanie zgody odnośnie publikacji danych INFARMA',
      url: 'https://medicon.coste.pl/files/wycofanie_zgody.pdf',
    },
    {
      name: 'Zmiana danych',
      url: 'https://medicon.coste.pl/files/zmiana_danych.pdf',
    },
    {
      name: 'Oświadczenie do przewodniczenia sesji',
      url: 'https://medicon.coste.pl/files/oswiadczenie.pdf',
    },
  ],
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
