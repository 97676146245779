import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api-service';
import { AppConfig } from 'src/app/models/app-config.model';

@Injectable({ providedIn: 'root' })
export class AppConfigService extends ApiService {
  private http: HttpClient;
  private appConfig: AppConfig;

  constructor(handler: HttpBackend) {
    super();
    this.http = new HttpClient(handler);
  }

  loadAppConfig() {
    return this.http
      .get(`${this.apiUrl}/appConfig/get`)
      .toPromise()
      .then(data => {
        this.appConfig = data as AppConfig;
      });
  }

  isFunctionEnabled(functionName: string): boolean {
    return functionName in this.appConfig.functions && this.appConfig.functions[functionName];
  }

  getOptionValue(optionName: string): any {
    return optionName in this.appConfig.options ? this.appConfig.options[optionName] : null;
  }
}
