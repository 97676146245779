import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concat } from 'rxjs/operators';
import { apiUrl } from 'src/app/misc/api';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class JwtRefreshInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private http: HttpClient) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldHandleExpired(request)) {
      return this.handleExpired(request);
    }
    return next.handle(request);
  }

  private handleExpired(request: HttpRequest<any>): Observable<HttpEvent<any>> {
      return this.authService.refreshToken()
        // tslint:disable-next-line
        .pipe(concat(this.http.request(request)));
  }

  private shouldHandleExpired(request: HttpRequest<any>): boolean {
    return this.authService.isAccessTokenExpired()
      && !this.authService.isRefreshing
      && request.url.includes(apiUrl());
  }
}
