import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { _t, getLangIdByCode } from 'src/app/misc/translations-helper';
import { FileUploadServiceService } from 'src/app/services/file-upload.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  title: string;
  serviceName: string;
  acceptedFiles: string;
  isMultipleFile: boolean;
  showAcceptedFilesInfo: boolean;
}

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
})
export class FileUploadModalComponent {

  @ViewChild('file') file;

  public files: Array<File> = new Array<File>();
  public progress;
  public modalTitle: string;
  public serviceName: string;
  public uploading = false;
  public uploadSuccessful = false;
  public isMultipleFile = false;
  public showAcceptedFilesInfo = false;
  public acceptedFiles: string = '';

  uploadSuccessfulChange: EventEmitter<boolean>;
  uploadCancelChange: EventEmitter<boolean>;
  uploadResults: EventEmitter<any>;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FileUploadModalComponent>,
    public uploadService: FileUploadServiceService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      this.uploadSuccessfulChange = new EventEmitter<boolean>();
      this.uploadCancelChange = new EventEmitter<boolean>();
      this.uploadResults = new EventEmitter<any>();
      this.serviceName = data.serviceName;
      this.modalTitle = data.title;
      this.showAcceptedFilesInfo = data.showAcceptedFilesInfo;
      this.isMultipleFile = data.isMultipleFile;
      this.acceptedFiles = data.acceptedFiles;
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        if (files[key].size / 1024 / 1024 < environment.maxFileSizeMB) {
          this.files.push(files[key]);
        } else {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              title: _t('Errors.FileUpload.FileUploadError'),
              message: this.translate.instant(_t('Errors.FileUpload.FileSizeError'), {
                fileSize: environment.maxFileSizeMB,
              }),
            },
            panelClass: 'confirmation',
          });
          dialogRef.componentInstance.closeChange.subscribe(() => {
            this.addFiles();
          });
        }
      }
    }
  }

  addButtonInfo(): string {
    const info = this.isMultipleFile ? _t('Common.FileUpload.AddFiles') : _t('Common.FileUpload.AddFile');
    const acceptedFilesInfo = this.showAcceptedFilesInfo ? `(${this.acceptedFiles})` : '';
    return `${this.translate.instant(info)} ${acceptedFilesInfo}`;
  }

  removeFile(i: number): void {
    this.files.splice(i, 1);
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  cancel(): void {
    this.dialogRef.close();
    this.uploadCancelChange.next(true);
  }

  uploadFiles() {
    const allProgressObservables = [];
    this.uploading = true;

    if (this.uploadSuccessful) {
      return this.dialogRef.close();
    }

    this.progress = this.uploadService.upload(this.files, this.serviceName, (error) => {
      let msg = _t('Errors.Upload.Message');
      this.dialogRef.close();

      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors['form.file']) {
        msg = error.error.errors['form.file'][getLangIdByCode(this.translate.getDefaultLang())];
      }

      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: _t('Errors.Upload.Title'),
          message: msg,
        },
        panelClass: 'confirmation',
      });
    });

    for (const key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
      (this.progress[key].fileResult as Subject<any>).subscribe(result => this.uploadResults.emit(result));
    }

    this.dialogRef.disableClose = true;

    forkJoin(allProgressObservables).pipe().subscribe(end => {
      this.dialogRef.disableClose = false;
      this.uploadSuccessful = true;
      this.uploading = false;
      this.uploadSuccessfulChange.emit(this.uploadSuccessful);
    });
  }

}
